/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { ArrowRight } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { darkThemeStyle } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import { FloatingFocusManager, useFloating } from '@floating-ui/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../interfaces/State';
import { selectStreakDays } from '../../redux/selectors';

import { StreakWidget } from './StreakWidget';

export type DailyStreakScreenProps = {
  onExit: () => void;
};

const getHintText = ({ streakDays }: { streakDays: number }) => {
  if (streakDays === 1) {
    return { key: 'DailyStreakModal.hintFirstDay' };
  }
  if (streakDays < 7) {
    return {
      key: 'DailyStreakModal.hintFirstWeek',
      options: { count: 7 - streakDays },
    };
  }
  return { key: 'DailyStreakModal.hintLongTerm', options: { chance: 74 } };
};

const DailyStreakScreen: React.FC<DailyStreakScreenProps> = (props) => {
  const { onExit } = props;

  const { context } = useFloating();
  const autoFocusRef = useRef(null);

  const { t } = useTranslation();
  const { isAboveSmall } = useMediaQuery();
  const streakDays = useSelector(selectStreakDays);

  if (streakDays === undefined) {
    return null;
  }

  const hintText = getHintText({ streakDays });

  return (
    <FloatingFocusManager
      context={context}
      initialFocus={autoFocusRef}
      modal={true}
      closeOnFocusOut={false}
    >
      <div
        css={[
          darkThemeStyle,
          {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: tokens.colors.navy,
            zIndex: tokens.zIndex.modal,
            display: 'flex',
            flexDirection: 'column',
            padding: tokens.spacing.xlarge,
            justifyContent: 'center',
            alignItems: 'stretch',
          },
        ]}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            [mediaQuery.aboveSmall]: { flexGrow: 0 },
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <StreakWidget
            isMobile={!isAboveSmall}
            streakLengthInDays={streakDays}
          ></StreakWidget>
          <Heading
            css={{
              marginTop: tokens.spacing.xlarge,
              fontSize: '32px',
            }}
          >
            {t(
              `${
                streakDays === 1
                  ? 'DailyStreakModal.headerStarted'
                  : 'DailyStreakModal.headerExtended'
              }`,
            )}
          </Heading>
          <Paragraph
            css={{
              marginBottom: tokens.spacing.xlarge,
            }}
          >
            {t(hintText.key, hintText.options)}
          </Paragraph>
        </div>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Button
            ref={autoFocusRef}
            variant="primary"
            iconRight={isAboveSmall ? <ArrowRight></ArrowRight> : undefined}
            fullWidth={!isAboveSmall}
            onClick={onExit}
          >
            {t('DailyStreakModal.cta')}
          </Button>
        </div>
      </div>
    </FloatingFocusManager>
  );
};

export default DailyStreakScreen;
